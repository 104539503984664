<script lang="ts">
	import Image from '$lib/Components/Layout/Image/Image.svelte';

	import { fly, slide } from 'svelte/transition';

	type ButtonType = 'anchor' | 'button';
	type Color = 'yellow' | 'blue' | 'grey' | 'transparent' | 'white';
	interface Props {
		href?: string;
		class?: string;
		buttonType?: ButtonType;
		color?: Color;
		border?: string;
		mobileHeight?: string;
		rel?: string;
		nowrap?: boolean;
		disabled?: boolean;
		icon?: Record<string, unknown>;
		loading?: boolean;
		active?: boolean;
		target?: string;
		onclick?: (event: MouseEvent) => void;
		children?: import('svelte').Snippet;
	}

	let {
		href = '',
		buttonType = 'anchor',
		color = 'yellow',
		border = '',
		mobileHeight = 'normal',
		rel = '',
		nowrap = false,
		disabled = null,
		icon = null,
		loading = false,
		active = true,
		target = null,
		class: className = undefined,
		onclick,
		children,
	}: Props = $props();
</script>

<div
	class="button relative grid w-full place-items-center"
	class:no-wrap={nowrap}
>
	{#if loading}
		<div
			transition:fly|local
			class="absolute right-0 grid h-full place-items-center"
		>
			<svg
				class="m-2 w-6 stroke-indow-white"
				stroke="#000"
				viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg"
				><g class="spinner"
					><circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3" /></g
				></svg
			>
		</div>
	{/if}
	{#if buttonType == 'anchor'}
		<a
			{onclick}
			{rel}
			{href}
			{target}
			class:bg-indow-yellow={color == 'yellow'}
			class:!text-indow-white={color == 'yellow' || color == 'blue'}
			class:bg-indow-blue={color == 'blue'}
			class:bg-indow-grey-dark={color == 'grey'}
			class:bg-transparent={color == 'transparent'}
			class:max-md:!px-4={mobileHeight == 'thin'}
			class:max-md:!py-2={mobileHeight == 'thin'}
			class:border={border == 'white' || color == 'blue' || color == 'white'}
			class:!border-solid={border == 'white' ||
				color == 'blue' ||
				color == 'white'}
			class:border-white={border == 'white' || color == 'blue'}
			class:border-indow-blue={color == 'white'}
			class:text-indow-blue={color == 'white'}
			class:grid={icon}
			class:gap-4={icon}
			class:cursor-not-allowed={disabled}
			class:grid-cols-[1rem,1fr]={icon}
			class={[
				'wrap hyphens-none rounded px-6 py-4 text-center text-base font-bold uppercase leading-none tracking-widest text-indow-white no-underline hover:no-underline focus:no-underline',
				className ?? '',
			].join(' ')}
			transition:slide|local={{ axis: 'x' }}
		>
			{@render children?.()}
		</a>
	{:else if buttonType == 'button'}
		{#if active}
			<button
				{onclick}
				type="submit"
				class:bg-indow-yellow={color == 'yellow'}
				class:!text-indow-white={color == 'yellow' || color == 'blue'}
				class:bg-indow-blue={color == 'blue'}
				class:bg-indow-grey-dark={color == 'grey'}
				class:bg-transparent={color == 'transparent'}
				class:px-4={mobileHeight == 'thin'}
				class:py-2={mobileHeight == 'thin'}
				class:md:!px-10={mobileHeight == 'thin'}
				class:md:!py-5={mobileHeight == 'thin'}
				class:border={border == 'white' || color == 'blue'}
				class:!border-solid={border == 'white' || color == 'blue'}
				class:border-white={border == 'white' || color == 'blue'}
				class={[
					'wrap hyphens-none rounded px-6 py-4 text-center text-base font-bold uppercase leading-none tracking-widest text-indow-white no-underline hover:no-underline focus:no-underline',
					className ?? '',
				].join(' ')}
				class:grid={icon}
				class:gap-4={icon}
				class:grid-cols-[1rem,1fr]={icon}
				{disabled}
				class:cursor-not-allowed={disabled}
				class:!bg-indow-grey-light={disabled}
				transition:slide|local={{ axis: 'x' }}
			>
				{#if icon}
					<Image src={icon} />
				{/if}

				{@render children?.()}
			</button>
		{/if}
	{/if}
</div>

<style>
	.spinner {
		transform-origin: center;
		animation: spinner_rotate 2s linear infinite;
	}
	.spinner circle {
		stroke-linecap: round;
		animation: spinner_offset 1.5s ease-in-out infinite;
	}
	@keyframes spinner_rotate {
		100% {
			transform: rotate(360deg);
		}
	}
	@keyframes spinner_offset {
		0% {
			stroke-dasharray: 0 150;
			stroke-dashoffset: 0;
		}
		47.5% {
			stroke-dasharray: 42 150;
			stroke-dashoffset: -16;
		}
		95%,
		100% {
			stroke-dasharray: 42 150;
			stroke-dashoffset: -59;
		}
	}
</style>
